import * as React from "react";
import Login from "../Components/Login";
import {ChakraProvider, Flex} from "@chakra-ui/react";
import "../Theme/Theme.css"

export default function LoginPage(){
    return (
    <ChakraProvider>
        <Flex className={'LogPage'} alignItems={'center'} justify={'center'}>
            <Flex></Flex>
            <Flex>
            <Login/>
            </Flex>
            <Flex></Flex>
        </Flex>
    </ChakraProvider>
    );
}
