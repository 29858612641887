import React, {useEffect, useState} from "react";
import {Input, message, Card, Button, Image} from "antd";
import exp from "constants";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {app} from "../Firebase/config";
import {CategoriesCollection, editCatIMG, firestore} from "../Firebase/Controller";
import {useNavigate, useParams} from "react-router-dom";
import { NewItemType } from "../Types/item";
import { DocumentData, deleteDoc, doc, getDoc, onSnapshot } from "firebase/firestore";

const ImageUploader = () => {

    const storage = getStorage(app);

    const {id} = useParams()

    const [items, setItems] = useState<NewItemType[]>([]);
    const [item, setItem] = useState({Category:'',CategoryIMG:''});

    useEffect(()=>onSnapshot(CategoriesCollection,
        (snapshot) => {
            setItems(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    }
                }))
        }),[]);
        const {Category , CategoryIMG} = item
useEffect(()=>{
        const DOC = async (id: string | undefined, data:any) =>{
            const docRef = doc(firestore,`Categories/${id}`);
            const docSnap = await getDoc(docRef);
            data = docSnap.data()
            setItem(data)
            return;
        }
        DOC(id,item)
    },[])

    const storageRef = ref(storage,CategoryIMG)
    console.log(storageRef)
    const DelIMG =() =>{
        const storageRef = ref(storage,CategoryIMG)
        deleteObject(storageRef)
        console.log(storageRef)
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imageFile, setImageFile] = useState<File>()
    const [downloadUrl, setDownloadUrl] = useState('')
    const [uploading,setUploading] = useState(false)
    const [uploadProgress,setUploadProgress] = useState(0)

    const handleSelectedFile = (files: any) => {
        if (files && files[0].size < 10000000){
            setImageFile(files[0])
        }else {
            message.error('file too large!!')
        }
        DelIMG();
    }

    const handleUpload = () => {
        const storageRef = ref(storage,CategoryIMG)
        deleteObject(storageRef)
        console.log(storageRef)
        if(imageFile){
            const storageRef = ref(storage,`/images/${imageFile.name}`)
            const uploadTask = uploadBytesResumable(storageRef,imageFile);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadProgress(progress);
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    message.error(error.message)
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setDownloadUrl(url);
                        editCatIMG(id,{CategoryIMG:url})
                        console.log(CategoryIMG)
                    });
                }
            );
        }else{
            message.error('File not found')
        }
    }

    const navigate = useNavigate()

    const Delete = async (id: string) => {
        const storageRef = ref(storage,CategoryIMG)
        deleteObject(storageRef)
        const document = doc(firestore,`Categories/${id}`);
        await deleteDoc(document);
        navigate(`${process.env.PUBLIC_URL}/Category`);
    }

    return (
        <div className={'container mt-5'}>
            <div className={'col-lg-8 offset-lg-2'}>
                <Input type={'file'} accept={'image'} onChange={files =>handleSelectedFile(files.target.files)}/>
            </div>
            <div>
                <Card>
                    <h5>{imageFile && imageFile.name}</h5>
                    <Button loading={uploading} type={'primary'} onClick={handleUpload}>Upload</Button>
                    <Button loading={uploading} type={'primary'} onClick={()=>Delete(id as string)}>Delete</Button>
                    {downloadUrl &&
                        <>
                            <Image src={downloadUrl}/>
                            <p>{downloadUrl}</p>
                        </>
                    }
                </Card>
            </div>
        </div>
    )
}

export default ImageUploader
