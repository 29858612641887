import * as React from 'react';
import {
    Button,
    ChakraProvider, FormControl, FormLabel, Input,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,Text
} from "@chakra-ui/react";
import { useState } from 'react';
import {addDoc, collection, doc, setDoc} from "firebase/firestore";
import {v4 as uuidv4} from 'uuid';
import {AddCategory, CategoriesCollection, firestore} from "../Firebase/Controller";
import {useNavigate, useParams} from "react-router-dom";
import {Items} from "../Types/item";

export default function CreateCategory() {

    const {id} = useParams();

    const itemsCollection = collection(firestore,`Categories/${id}/items`)

    const {isOpen, onOpen, onClose} = useDisclosure()

    const [item, setItem] = useState('')
    const [itemPrice, setItemPrice] = useState('')
    const [itemDisc, setItemDisc] = useState('')

    const AddItem = async (ITEM: Items) => {
        const newItem = await addDoc(itemsCollection, {...ITEM})
        console.log(ITEM.Id)
    }

    const AddNew = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        AddItem({
            item: item,
            itemPrice: itemPrice,
            itemDisc: itemDisc,
        })
        onClose();
        console.log('add item successfully')
    }

    return (
        <ChakraProvider>
            <Button variant='solid' colorScheme='whiteAlpha' color={'#D4A373'} onClick={onOpen}>
                Add
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'rgba(0,0,0,0.8)'} className={'modal'}>
                    <form onSubmit={(e)=>AddNew(e)}>
                        <ModalHeader color={'white'}>Add a Category</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <FormControl>
                                <FormLabel color={'lightgray'} ml={'1%'}></FormLabel>
                                <Text color={'white'}>Name</Text>
                                <Input mb={'2%'} id={'Category'} type="text" value={item} color={'white'} placeholder={'name'} onChange={(e)=>setItem(e.target.value)} required/>
                                <Text color={'white'}>Description</Text>
                                <Input mb={'2%'} id={'Category'} type="text" value={itemDisc} color={'white'} placeholder={'name'} onChange={(e)=>setItemDisc(e.target.value)}/>
                                <Text color={'white'}>Price</Text>
                                <Input mb={'2%'} id={'Category'} type="text" value={itemPrice} color={'white'} placeholder={'name'} onChange={(e)=>setItemPrice(e.target.value)} required/>
                            </FormControl>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme='blackAlpha' color={'#D4A373'} mr={3} onClick={onClose}>
                                Close
                            </Button>
                            <Button variant='ghost' color={'#D4A373'} type={'submit'}>
                                Add
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    )
}
