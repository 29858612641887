import * as React from "react";
import {
    Box,
    Flex,
    Text,
    IconButton,
    Button,
    Stack,
    Collapse,
    Icon,
    Popover,
    PopoverTrigger,
    PopoverContent,
    useColorModeValue,
    useBreakpointValue,
    useDisclosure, Heading, ButtonGroup,
} from '@chakra-ui/react'
import {
    HamburgerIcon,
    CloseIcon,
    ChevronDownIcon,
    ChevronRightIcon,
} from '@chakra-ui/icons'
import CreateCategory from "./CreateCategory";
import EditCategory from "./EditCategory";
import { getAuth, signOut } from "firebase/auth";
import {useNavigate} from "react-router-dom";
import { message } from "antd";
import {app} from "../Firebase/config";
import { SignOut } from "../Firebase/Controller";


export default function CategoryNav() {

    const navigate = useNavigate();

    const SgnOut = () => {
        SignOut();
        navigate(`${process.env.PUBLIC_URL}/login`);
    }

    const { isOpen, onToggle } = useDisclosure()

    return (
        <Box>
            <Flex
                bg={'rgba(0,0,0,0.8)'}
                color={useColorModeValue('gray.600', 'white')}
                minH={'60px'}
                py={{ base: 2 }}
                px={{ base: 4 }}
                borderBottom={1}
                borderStyle={'solid'}
                borderColor={'gray'}
                align={'center'}
                mb={'2%'}
            >
                <Flex flex={{ base: 1 }}>
                    <Heading
                        textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
                        fontWeight={'lighter'}
                        color={'#D4A373'}>
                        Categories
                    </Heading>
                </Flex>
                <ButtonGroup>
                    <CreateCategory/>
                    <Button
                        variant='solid' colorScheme='whiteAlpha' color={'#D4A37'} onClick={()=>SgnOut()}>
                        Sign Out
                    </Button>
                </ButtonGroup>
            </Flex>
        </Box>
    )
}

