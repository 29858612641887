import * as React from 'react';
import {
    Button,
    ButtonGroup,
    Card,
    CardBody,
    CardFooter,
    ChakraProvider,
    Divider,
    Flex, FormControl, FormLabel,
    Grid,
    GridItem,
    Heading, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay,
    SimpleGrid,
    Stack,
    Text, useDisclosure
} from "@chakra-ui/react";
import {Link, useParams} from "react-router-dom";
import {firestore} from "../Firebase/Controller";
import {collection, deleteDoc, doc, onSnapshot} from "firebase/firestore";
import {useEffect, useState} from "react";
import {Items} from '../Types/item';
import '../Theme/Theme.css';


export default function Items2(){

    const {id} = useParams();

    const [item, setItem] = useState<Items[]>([]);


    const itemsCollection = collection(firestore,`Categories/${id}/items`)
    useEffect(()=>onSnapshot(itemsCollection,
        (snapshot) => {
            setItem(
                snapshot.docs.map((doc) => {
                    return {
                        Id: doc.id,
                        ...doc.data(),
                    }
                }))
        }),[]);

    const DeleteItem = async (Id:string) => {
        const document = doc(firestore,`Categories/${id}/items/${Id}`)
        await deleteDoc(document);
        onClose();
    }

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
            <ChakraProvider>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                    {item.map((item) =>{
                        return (
                            <div>
                                <Card size='sm' className={'card'} bg={'rgba(0,0,0,0.5)'} border={'0'}>
                                    <CardBody>
                                        <Stack mt='3' spacing='2' pb={'3'}>
                                            <Heading size='md' color={'white'}>{item.item}</Heading>
                                            <Text color={'#D4A373'} h={'8vh'}>{item.itemDisc}</Text>
                                            <Text color={'white'} fontSize={'xl'}>{item.itemPrice}</Text>
                                        </Stack>
                                    </CardBody>
                                    <Divider color={'gray'} orientation={'horizontal'} w={'90%'} ml={'5%'}/>
                                    <CardFooter>
                                        <ButtonGroup>
                                            <Button variant='solid' colorScheme='whiteAlpha' color={'#D4A373'}
                                                    onClick={()=>(DeleteItem(item.Id as string))}>
                                                Delete
                                            </Button>
                                        </ButtonGroup>
                                    </CardFooter>
                                    <Divider/>
                                </Card>
                            </div>
                        )})}
                </SimpleGrid>
            </ChakraProvider>
    )
}
