'use client'

import {
    Box,
    chakra, ChakraProvider,
    Flex,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    useColorModeValue,
} from '@chakra-ui/react'
import HomeNav from './HomeNav'
import {auth, CategoriesCollection, firestore} from "../Firebase/Controller";
import { collection, collectionGroup, getCountFromServer } from 'firebase/firestore';
import {useAuthState} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

interface StatsCardProps {
    title: string
    stat: number
}

// @ts-ignore
const snapshot = await getCountFromServer(CategoriesCollection);

let CategoryCount: number
CategoryCount = (snapshot.data().count)

//@ts-ignore
const snap = await getCountFromServer(collectionGroup(firestore,'items'));

let ItemsCount: number
ItemsCount = (snap.data().count)



function StatsCard(props: StatsCardProps) {
    const { title, stat } = props
    return (
        <Flex gap={10} w='30vh'>
        <Stat
            px={{ base: 0, md: 8 }}
            py={'5'}
            shadow={'xl'}
            border={'1px solid'}
            borderColor={'#D4A373'}
            rounded={'lg'}
            className={'Stat'}
        >
            <StatLabel fontWeight={'medium'} fontSize={'xl'} isTruncated mb={'2%'} textAlign={'center'}>
                {title}
            </StatLabel>
            <StatNumber fontSize={'2xl'} fontWeight={'lighter'} textAlign={'center'}>
                {stat}
            </StatNumber>
        </Stat>
    </Flex>
    )
}

export default function Main() {

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            return;
        }else {
            navigate(`${process.env.PUBLIC_URL}/login`)
        }
    },[user, loading]);

    return (
        <ChakraProvider>
        <HomeNav/>
        <Box maxW="7xl" mx={'auto'} pt={2} px={{ base: 2, sm: 12, md: 17 }} alignItems={'center'} justifyItems={'center'}>
            <chakra.h1 color={'#D4A373'} fontSize={'4xl'} py={10} fontWeight={'bold'} ml={'2%'}>
                Menu Manager
            </chakra.h1>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }} color={'white'}>
                <StatsCard title='Categories' stat={snapshot.data().count}/>
                <StatsCard title={'Items'} stat={snap.data().count}/>
            </SimpleGrid>
        </Box>
        </ChakraProvider>
    )
}
