import * as React from "react";
import {useState,useEffect} from "react";
import {
    ChakraProvider,
    Divider,
    Flex,
    Heading,
    HStack,
    SimpleGrid,
    GridItem,
    Image,
    Stack,
    Text,
    Card,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    Box,
    FormControl,
    FormLabel,
    Input
} from "@chakra-ui/react";
import { initializeApp } from "firebase/app";
import {
    deleteDoc,
    doc,
    DocumentData,
    DocumentReference,
    getDoc,
    getFirestore,
    onSnapshot,
    query,
    setDoc,
    where
} from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {getStorage, listAll, ref, getDownloadURL, deleteObject} from 'firebase/storage';
import firebaseConfig from "../Firebase/config";
import "../Theme/Theme.css"
import EditCategory from "./EditCategory";
import CategoryNav from "./CategoryNav";
import {v4 as uuidv4} from "uuid";
import {CategoriesCollection, editCat, firestore} from "../Firebase/Controller";
import {NewItemType} from "../Types/item";
import {Link, useNavigate} from "react-router-dom";


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function Categories() {

    const storage = getStorage(app);

    const [items, setItems] = useState<NewItemType[]>([]);

    useEffect(()=>onSnapshot(CategoriesCollection,
        (snapshot) => {
            setItems(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    }
                }))
        }),[]);

    return(
        <div>
                    <ChakraProvider>
                        <CategoryNav/>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                            {items.map((item) =>{
                                return (
                                <div>
                                    <Card size='sm' className={'card'} bg={'rgba(0,0,0,0.5)'} border={'0'}>
                                        <CardBody>
                                            <Image
                                                src={item.CategoryIMG}
                                                borderRadius='lg'
                                                maxH={'23vh'}
                                                maxW={'23vw'}
                                            />
                                            <Stack mt='3' spacing='1' pb={'3'}>
                                                <Heading size='md' color={'white'}>{item.Category}</Heading>
                                            </Stack>
                                        </CardBody>
                                        <Divider color={'gray'} orientation={'horizontal'} w={'90%'} ml={'5%'}/>
                                        <CardFooter>
                                            <ButtonGroup>
                                                <Link to={`/Category/${item.id}`}><Button variant='solid' colorScheme='whiteAlpha' color={'#D4A373'}>
                                                    View
                                                </Button></Link>
                                            </ButtonGroup>
                                        </CardFooter>
                                        <Divider/>
                                    </Card>
                                </div>
        )})}
                        </SimpleGrid>
                    </ChakraProvider>
        </div>
    )
}
export default Categories;
