import * as React from 'react';
import {
    Button,
    ChakraProvider, FormControl, FormLabel, Input,
    Modal, ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure
} from "@chakra-ui/react";
import { useState } from 'react';
import {doc, setDoc} from "firebase/firestore";
import {v4 as uuidv4} from 'uuid';
import {AddCategory, CategoriesCollection} from "../Firebase/Controller";
import {useNavigate} from "react-router-dom";
import ImageUploader from "./ImageUploader";
import {getDownloadURL, getStorage, ref, uploadBytesResumable} from "firebase/storage";
import {app} from "../Firebase/config";
import {Card, Image, message} from "antd";

export default function CreateCategory(){


    const storage = getStorage(app);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [imageFile, setImageFile] = useState<File>()
    const [downloadUrl, setDownloadUrl] = useState('')
    const [uploading,setUploading] = useState(false)
    const [uploadProgress,setUploadProgress] = useState(0)

    const handleSelectedFile = (files: any) => {
        if (files && files[0].size < 10000000){
            setImageFile(files[0])
        }else {
            message.error('file too large!!')
        }
    }

    let URL:string

    const handleUpload = () => {
        if(imageFile){
            const storageRef = ref(storage,`/images/${imageFile.name}`)
            const uploadTask = uploadBytesResumable(storageRef,imageFile);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    setUploadProgress(progress);
                    console.log('Upload is ' + progress + '% done');
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                            break;
                        case 'running':
                            console.log('Upload is running');
                            break;
                    }
                },
                (error) => {
                    message.error(error.message)
                },
                () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setDownloadUrl(url);
                        AddCategory({
                            Category: Category,
                            CategoryIMG:url})
                    });
                }
            );
        }else{
            message.error('File not found')
        }
    }

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [Category, setCategory] = useState('')

    const navigate = useNavigate();
    const AddNew = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        AddCategory({
            Category: Category,
            CategoryIMG:downloadUrl,
        })
        onClose();
    }

    return (
        <ChakraProvider>
            <Button variant='solid' colorScheme='whiteAlpha' color={'#D4A373'} onClick={onOpen}>
                Add
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'rgba(0,0,0,0.8)'} className={'modal'}>
                    <form>
                    <ModalHeader color={'white'}>Add a Category</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                                    <FormControl>
                                        <FormLabel color={'lightgray'} ml={'1%'}></FormLabel>
                                        <Input mb={'2%'} id={'Category'} type="text" value={Category} color={'white'} placeholder={'Category'} onChange={(e)=>setCategory(e.target.value)} required/>
                                        <div className={'col-lg-8 offset-lg-2'}>
                                            <Input type={'file'} accept={'image'} onChange={files =>handleSelectedFile(files.target.files)}/>
                                        </div>
                                        <div>
                                                <h5>{imageFile && imageFile.name}</h5>
                                                {downloadUrl &&
                                                    <>
                                                        <Image src={downloadUrl}/>
                                                        <p>{downloadUrl}</p>
                                                    </>
                                                }
                                        </div>
                                    </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme='blackAlpha' color={'#D4A373'} mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme='blackAlpha' color={'#D4A373'} mr={3} onClick={handleUpload}>Upload</Button>
                    </ModalFooter>
                        </form>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    )
}
function alert(err: any) {
    throw new Error('Function not implemented.');
}

