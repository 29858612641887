import {
    Box,
    Flex,
    Heading,
    Text,
    Stack,
    Container,
    Avatar,
    useColorModeValue,
    SimpleGrid,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    FormControl, FormLabel, ModalFooter, useDisclosure,
} from '@chakra-ui/react'
import {useEffect, useState} from "react";
import {Message} from "../Types/item";
import {deleteDoc, doc, onSnapshot} from "firebase/firestore";
import {ContactCollection, firestore} from "../Firebase/Controller";
import * as React from "react";

interface Props {
    children: React.ReactNode
}

const Testimonial = (props: Props) => {
    const { children } = props

    return <Box bg={'rgba(0,0,0,0.2)'} className={'card'} p={'2vh'} borderRadius={'10%'}>{children}</Box>
}

const TestimonialContent = (props: Props) => {
    const { children } = props

    return (
        <Stack
            bg={'rgba(0,0,0,0.8)'}
            boxShadow={'lg'}
            p={8}
            rounded={'xl'}
            align={'center'}
            pos={'relative'}
            _after={{
                content: `""`,
                w: 0,
                h: 0,
                borderLeft: 'solid transparent',
                borderLeftWidth: 16,
                borderRight: 'solid transparent',
                borderRightWidth: 16,
                borderTop: 'solid',
                borderTopWidth: 16,
                borderTopColor: 'rgba(0,0,0,0.8)',
                pos: 'absolute',
                bottom: '-16px',
                left: '50%',
                transform: 'translateX(-50%)',
            }}>
            {children}
        </Stack>
    )
}

const TestimonialHeading = (props: Props) => {
    const { children } = props

    return (
        <Heading as={'h3'} fontSize={'xl'} color={'white'}>
            {children}
        </Heading>
    )
}

const TestimonialText = (props: Props) => {
    const { children } = props

    return (
        <Text
            textAlign={'center'}
            color={'#D4A373'}
            fontSize={'sm'}>
            {children}
        </Text>
    )
}

const TestimonialAvatar = ({
                               name,
                           }: {
    name: string | undefined
}) => {
    return (
        <Flex align={'center'} mt={8} direction={'column'}>
            <Stack spacing={-1} align={'center'}>
                <Text fontWeight={600} fontSize={'2xl'} color={'white'}>name</Text>
            </Stack>
        </Flex>
    )
}

export default function Contact() {

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [items, setItems] = useState<Message[]>([]);

    useEffect(()=>onSnapshot(ContactCollection,
        (snapshot) => {
            setItems(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    }
                }))
        }),[]);

    const Delete = async (id: string) => {
        const document = doc(firestore,`Contact/${id}`);
        await deleteDoc(document);
        onClose();
    }

    return (
        <Box bg={'transparent'}>
            <Container maxW={'7xl'} py={16}>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={5}>
                    {items.map((item)=>{
                        return(
                    <Testimonial>
                        <TestimonialContent>
                            <TestimonialHeading>{item.subject}</TestimonialHeading>
                            <TestimonialText>
                                {item.message}
                            </TestimonialText>
                        </TestimonialContent>
                        <Flex align={'center'} mt={8} direction={'column'}>
                            <Stack spacing={-1} align={'center'}>
                                <Text fontWeight={600} fontSize={'xl'} color={'white'}>{item.name}</Text>
                                <Text fontWeight={600} color={'white'} mb={'2%'}>{item.email}</Text>
                            </Stack>
                            <Button variant='solid' colorScheme='whiteAlpha' color={'#D4A373'}
                                    onClick={()=>(Delete(item.id as string))}>
                                Delete
                            </Button>
                        </Flex>
                    </Testimonial>
                        )
                    })}
                </SimpleGrid>
            </Container>
        </Box>
    )
}
