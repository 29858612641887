import {addDoc, collection, deleteDoc, doc, getDoc, getFirestore, setDoc} from "firebase/firestore";
import {initializeApp} from "firebase/app";
import firebaseConfig from "./config";
import {useNavigate, useParams} from "react-router-dom";
import {useDisclosure} from "@chakra-ui/react";
import {Items, NewItemType} from "../Types/item";
import {getAuth, signOut} from "firebase/auth";
import {message} from "antd";

export const app= initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

export const CategoriesCollection = collection(firestore,'Categories');
export const itemCollection = collection(firestore,'Categories',':id/items')

export const ContactCollection = collection(firestore,'Contact')

export const AddCategory = async (Data:NewItemType) => {
    const newCategory = await addDoc(CategoriesCollection,{...Data})
}

export const editCat = async (id: string | undefined, docData: any) => {
    const getCat = doc(firestore,`Categories/${id}`);
    await setDoc(getCat, docData, {merge: true});
}
export const editCatIMG = async (id: string | undefined, docData: any) => {
    const getCat = doc(firestore,`Categories/${id}`);
    await setDoc(getCat, docData, {merge: true});
}

export const DOC = async (id: string | undefined, data:any) =>{
    const docRef = doc(firestore,`Categories/${id}`);
    const docSnap = await getDoc(docRef);
    data = docSnap.data()
    console.log(data)
}

export const auth = getAuth(app);

export const SignOut = () => {
    signOut(auth).then(() => {
        console.log('signed out!!')
    }).catch((error) => {
        message.error(error.message)
    });
}