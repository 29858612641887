import * as React from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getStorage} from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyDCjTED45bOafHhfxRSUcU_0Zz_29qS4Dw",
    authDomain: "woodlounge-d150e.firebaseapp.com",
    databaseURL: "https://woodlounge-d150e-default-rtdb.firebaseio.com",
    projectId: "woodlounge-d150e",
    storageBucket: "woodlounge-d150e.appspot.com",
    messagingSenderId: "1032359661856",
    appId: "1:1032359661856:web:4a1a8ad2276d6cc1b1c745",
    measurementId: "G-10PVXRDTLV"
};

export default firebaseConfig;
export const app= initializeApp(firebaseConfig);
