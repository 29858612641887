import * as React from 'react';
import SideBar from '../Components/sideBar';


export default function Dashboard(){
    return(
    <div>
        <SideBar/>
    </div>
    );
}
