'use client'

import React, {ReactNode, useEffect} from 'react'
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Text,
    Drawer,
    DrawerContent,
    useDisclosure,
    BoxProps,
    FlexProps,
    Image, Link,
} from '@chakra-ui/react'
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
} from "react-icons/fi"
import { IconType } from 'react-icons'
import { ReactText } from 'react'
import "../Theme/Theme.css"
import Main from './Home'
import Products from './Products'
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../Firebase/Controller";
import {useNavigate} from "react-router-dom";

interface LinkItemProps {
    name: string
    icon: IconType
    dest:string
}
const LinkItems: Array<LinkItemProps> = [
    {name: 'Home', icon: FiHome, dest:`/dashboard`},
    { name: 'Categories', icon: FiMenu, dest:`/dashboard/Category` },
    { name: 'Products', icon: FiMenu, dest:`/dashboard/Products` },
    { name: 'Contact', icon: FiStar, dest:`/dashboard/Contact` },
]

export default function Sidebar() {

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            return;
        }else {
            navigate(`${process.env.PUBLIC_URL}/login`)
        }
    },[user, loading]);

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <Box minH="100vh" className={'sideBar'}>
            <SidebarContent onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
            <Box ml={{ base: 0, md: 60 }} p="4" id={'#Main'}>
                <Products/>
            </Box>
        </Box>
    )
}

interface SidebarProps extends BoxProps {
    onClose: () => void
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
        <Box
            className={'sideBar1'}
            bg={'rgba(0,0,0,0.5)'}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.700', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between" mt={'10%'} mb={'5%'}>
                <Image src={require('../Assets/Logo.png')} w={'50%'} display={{ base: 'none', md: 'flex' }}/>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} dest={link.dest} color={'white'}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    )
}

interface NavItemProps extends FlexProps {
    icon: IconType
    children: ReactText
    dest:string
}
const NavItem = ({ icon, children, dest, ...rest }: NavItemProps) => {
    return (
        <Box
            as="a"
            href={dest}
            style={{ textDecoration: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: '#6b5034',
                    color: 'white',
                }}
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Box>
    )
}

interface MobileProps extends FlexProps {
    onOpen: () => void
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={'transparent'}
            className={'mobileNav'}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.700', 'gray.700')}
            justifyContent="flex-start"
            {...rest}>
            <IconButton
                variant="unstyled"
                color={'white'}
                onClick={onOpen}
                size={'lg'}
                fontSize={'150%'}
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Image src={require('../Assets/Logo.png')} ml={'5%'} w={'15%'}/>
        </Flex>
    )
}
