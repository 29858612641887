import React, {useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {ChakraProvider, FormControl, FormLabel, Input, Button} from '@chakra-ui/react';
import {useDisclosure} from '@chakra-ui/hooks';
import {addDoc, collection, doc, setDoc, updateDoc} from 'firebase/firestore';
import {app, CategoriesCollection, editCat,firestore} from "../Firebase/Controller";
import {deleteObject, getDownloadURL, getStorage, ref, uploadBytes} from "firebase/storage";
import firebase from "firebase/app";
import ImageUploader from "./ImageUploader";

const EditCategory = () => {
    const {id} = useParams();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [inputValue, setInputValue] = useState('');

    const navigate = useNavigate();

    const handleUpdate = () => {
        editCat(id, {Category: inputValue});
        navigate(`${process.env.PUBLIC_URL}/Category`);
    };

    return (
        <ChakraProvider>
            <form>
                <FormControl>
                    <FormLabel color={'lightgray'} ml={'1%'}>
                        Category Name
                    </FormLabel>
                    <Input mb={'2%'} color={'white'} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
                    <ImageUploader/>
                </FormControl>
                <Button variant='ghost' color={'#D4A373'} type={'submit'} onClick={()=>handleUpdate()}>
                    Edit
                </Button>
            </form>
        </ChakraProvider>
    );
}
export default EditCategory;
