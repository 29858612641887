import * as React from 'react';
import {useState, useEffect} from 'react';
import {Box, Button, Flex, FormControl, FormLabel, Input, Stack, Text,} from '@chakra-ui/react'
import "../Theme/Theme.css";
import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, UserCredential} from "firebase/auth";
import {addDoc, collection, getDocs, getFirestore, query, where} from "firebase/firestore";
import firebaseConfig from "../Firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async (): Promise<void> => {
    try {
        const res: UserCredential = await signInWithPopup(auth, googleProvider);
        const user = res.user;
        const q = query(collection(db, "users"), where("uid", "==", user.uid));
        const docs = await getDocs(q);
        if (docs.docs.length === 0) {
            await addDoc(collection(db, "users"), {
                uid: user.uid,
                name: user.displayName,
                authProvider: "google",
                email: user.email,
            });
        }
    } catch (err) {
        console.error(err);
        alert(err);
    }
};

const logInWithEmailAndPassword = async (email: string, password: string): Promise<void> => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err);
    }
};


export default function Login() {
    const [email, setEmail] = useState ("");
    const [password, setPassword] = useState("");
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (loading) {
            return;
        }
        if (user) navigate(`/dashboard`);
    },[user, loading]);

    return (
        <Flex
            my={'10%'}
            h={'84vh'}
            w={'80vh'}
            align={'center'}
            justify={'center'}
            className={'LogContainer'}
            bg={'rgba(0,0,0,0.5)'}
            rounded={'lg'}
        >
            <Stack spacing={8} mx={'auto'} maxW={'lg'} w={'100%'} py={12} px={6}>
                <Stack align={'center'}>
                    <Text fontWeight={'lighter'} fontSize={'4xl'} color={'#D4A373'}>Wood Lounge</Text>
                    <Text fontSize={'lg'} color={'white'}>
                        Sign in to admin account
                    </Text>
                </Stack>
                <Box
                    rounded={'lg'}
                    bg={'transparent'}
                    boxShadow={'lg'}
                    p={8}>
                    <Stack spacing={4}>
                        <FormControl id="email">
                            <FormLabel color={'white'} fontWeight={'lighter'}>Email address</FormLabel>
                            <Input type="text" color={'white'} value={email} onChange={(e)=>setEmail(e.target.value)}/>
                        </FormControl>
                        <FormControl id="password">
                            <FormLabel color={'white'} fontWeight={'lighter'}>Password</FormLabel>
                            <Input type="password" color={'white'} value={password} onChange={(e)=>setPassword(e.target.value)}/>
                        </FormControl>
                            <Button
                                my={'10%'}
                                w={'100%'}
                                bg={'blue.400'}
                                color={'white'}
                                _hover={{
                                    bg: 'blue.500',
                                }}
                                onClick={()=> logInWithEmailAndPassword(email, password)}
                            >
                                Sign in
                            </Button>
                    </Stack>
                </Box>
            </Stack>
        </Flex>
    )
}
