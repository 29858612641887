import React from 'react';
import {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {ChakraProvider} from "@chakra-ui/react";
import LoginPage from "./Pages/LoginPage";
import Dashboard from "./Pages/Dashboard";
import Main from './Components/Home';
import CategoryBar from "./Components/CategoryBar";
import ProductBar from './Components/ProductBar';
import EditCatBar from './Components/EditCatBar';
import Contact from "./Components/Contact";
import ContactBar from './Components/ContactBar';


function App() {
  const [width, setWindowWidth] = useState<number>(0);

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };


  return (
      <ChakraProvider>
        <div className={'Main'}>
          <main>
            <Router>
              <Routes>
                <Route path={'/'} element={<LoginPage/>}></Route>
                <Route path={'/login'} element={<LoginPage/>}></Route>
                <Route path={'/dashboard'} element={<Dashboard/>}></Route>
                <Route path={'/Main'} element={<Main/>}></Route>
                <Route path={'/dashboard/Category'} element={<CategoryBar/>}></Route>
                <Route path={'/dashboard/Products'} element={<ProductBar/>}></Route>
                <Route path={`/dashboard/Categories/:id`} element={<ProductBar/>}></Route>
                <Route path={`/dashboard/Category/:id`} element={<EditCatBar/>}></Route>
                <Route path={'/dashboard/Contact'} element={<ContactBar/>}></Route>
              </Routes>
            </Router>
          </main>
        </div>
      </ChakraProvider>
  );
}

export default App;
