import * as React from "react";
import {useState,useEffect} from "react";
import {
    ChakraProvider,
    Divider,
    Flex,
    Heading,
    HStack,
    SimpleGrid,
    GridItem,
    Image,
    Stack,
    Text,
    Card,
    CardBody,
    CardFooter,
    ButtonGroup,
    Button,
    useColorModeValue, useBreakpointValue, Box, Menu, MenuButton, MenuList, MenuItem, useDisclosure
} from "@chakra-ui/react";
import {Link, useNavigate, useParams} from 'react-router-dom';
import { initializeApp } from "firebase/app";
import {doc, getFirestore, onSnapshot, query} from "firebase/firestore";
import { collection, getDocs } from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {getStorage, listAll, ref, getDownloadURL} from 'firebase/storage';
import firebaseConfig from "../Firebase/config";
import "../Theme/Theme.css"
import {Items, NewItemType} from "../Types/item";
import {CategoriesCollection, SignOut} from "../Firebase/Controller";
import { ChevronDownIcon } from "@chakra-ui/icons";
import Items2 from "./Items2";
import CreateItem from "./CreateItem";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const listRef = ref(storage, '/');
export default function Products(){
    const [items,setItems] = useState<NewItemType[]>([]);
    useEffect(()=>onSnapshot(CategoriesCollection,
        (snapshot) => {
            setItems(
                snapshot.docs.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc.data(),
                    }
                }))
        }),[]);

    const { isOpen, onOpen, onClose } = useDisclosure()

    const navigate = useNavigate();

    const SgnOut = () => {
        SignOut();
        navigate(`${process.env.PUBLIC_URL}/login`);
    }

    return(
        <div>
            <ChakraProvider>
                <Box>
                    <Flex
                        bg={'rgba(0,0,0,0.8)'}
                        minH={'60px'}
                        py={{ base: 2 }}
                        px={{ base: 4 }}
                        borderBottom={1}
                        borderStyle={'solid'}
                        borderColor={'gray'}
                        align={'center'}
                        mb={'2%'}
                    >
                        <Flex flex={{ base: 1 }}>
                            <Heading
                                fontWeight={'lighter'}
                                color={'#D4A373'}>
                                Products
                            </Heading>
                        </Flex>
                        <ButtonGroup>
                        <CreateItem/>
                        <Menu>
                            <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant='solid' colorScheme='whiteAlpha' color={'#D4A373'}>
                                Categories
                            </MenuButton>
                            <MenuList>
                                {items.map((item) => {
                                    return (
                                        <a href={`/dashboard/Categories/${item.id}`}><MenuItem>{item.Category}</MenuItem></a>
                                    ) })}
                            </MenuList>
                        </Menu>
                            <Button
                                variant='solid' colorScheme='whiteAlpha' color={'#D4A373'} onClick={()=>SgnOut()}>
                                Sign Out
                            </Button>
                        </ButtonGroup>
                    </Flex>
                </Box>
                <Items2/>
            </ChakraProvider>
        </div>
    )
}
